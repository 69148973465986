import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { GroupedArticles } from "../../../../hooks/useArticles";
import { useStoreState } from "../../state";
import { ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { BackButton, base64Encode, FilterParams } from "../areas/ThematicAreaView";
import { keyBy } from "./DataStories";
import ExploreIndicator, { ExploreIndicatorHeader, TargetBadge } from "./ExploreIndicator";
import { serializeGoalParams, unSort } from "./GoalContent";
import { OpenKeysContext } from "./Goals";

const ExploreTarget: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    isSearch?: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined;
    goal: string;
    target: string;
    area:[];
    store:[];
    filterParams: FilterParams
  }> = ({ blocks, varToTopics, fulfillResponse, placeDcids, goal, target, dataStories, filterParams, infographics, area, store }) => {

    const history = useHistory();
    const {backUrl} = useContext(OpenKeysContext)
    const [childTopics, setChildTopics] = useState<false | GroupedArticles | undefined | null>({});
    const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);

    useEffect(()=>{
      if(fulfillResponse?.relatedThings?.childTopics && sidebarMenuHierarchy[goal]){
        let topicItem = sidebarMenuHierarchy[goal].children.find(i=>i.key===`${ROOT_TOPIC}_${target}`)
        setChildTopics(!topicItem?.children ? {} : keyBy(topicItem?.children?.filter(a=>!a.key?.includes('summary-')).map(a=>({...a, number: a.key.split('_')[1]})), 'number'))
      }
    },[fulfillResponse])

    const color = theme.sdgColors[Number(goal) - 1];
    const targetDescriptions = useStoreState((s) => s.targetText);
    const targetText = targetDescriptions.byTarget[target];

  const goBack = () => {
    if(!backUrl) goList()
    else history.push(backUrl)
  };
  const goList = () => {
    let str = serializeGoalParams(filterParams)
    let str64 = base64Encode(str);
    history.push(routeDataCommonsConstants.GOAL + "?p=" + str64);
  }
    return <Layout style={{ height: "100%", flexGrow: 1 }}  id="top">
            <Layout.Content style={{ background: theme.searchBackgroundColor }}>

                <ExploreIndicatorHeader>
                  <Row>
                    {true && (
                      <Col flex="0 1">
                        <BackButton size="small" onClick={goBack}>
                          <ArrowLeftOutlined />
                        </BackButton>
                      </Col>
                    )}
                    <Col flex="1 1">
                      <span>
                        <TargetBadge
                          className={`-dc-target-header -dc-target-header-${target}`}
                          color={color}
                        >
                          {target}
                        </TargetBadge>
                        {targetText.replace(target, "")}
                      </span>
                    </Col>
                  </Row>
                </ExploreIndicatorHeader>
                
                {childTopics && Object.keys(childTopics)
                .sort(unSort)
                .map((indicator, i) => {
                  return <ExploreIndicator 
                    filterParams={filterParams}
                    area={area}
                    store={store}
                    fulfillResponse={fulfillResponse} 
                    goal={goal} 
                    target={target} 
                    indicator={indicator}
                    placeDcids={placeDcids} 
                    dataStories={dataStories?.[ROOT_TOPIC+'_'+indicator]}
                    hideBack={true}
                    infographics={infographics}
                    varToTopics={varToTopics}
                    blocks={blocks}
                  />
                })}
            </Layout.Content>
            </Layout>
  }
export default ExploreTarget