import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import ChartCarousel, {
  ChartCarouselContainer,
  CustomNextArrow,
  CustomPrevArrow,
} from "../areas/ChartCarousel";
import Indicator from "../areas/Indicator";
import { BackButton, FilterParams, IndicatorHeader } from "../areas/ThematicAreaView";
import {
  GoalChartContentBody
} from "../countries/CountriesContent";
import { Divider, ExploreBtn } from "../shared/components";
import { StoryContent } from "../shared/goals/IndicatorOverview";
import { StoryBreadcrumbs, StoryHeader } from "./DataStories";
import { OpenKeysContext } from "./Goals";
import { StoryView } from "./IndicatorCarousel";
import InfographicView from "./InfographicView";

const ExploreIndicator: React.FC<{
  fulfillResponse?: FulfillResponse;
  placeDcids: string[];
  // selectedVariableDcids: string[];
  isSearch?: boolean;
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  blockID?: number | undefined;
  goal: string;
  target: string;
  indicator: string;
  setCarouselProps: () => void | undefined;
  dataStories: {};
  hideBack?: boolean | undefined
  infographics?: [] | undefined
  area?: [],
  store?: [],
  filterParams: FilterParams
}> = ({
  fulfillResponse,
  placeDcids,
  goal,
  target,
  indicator,
  setCarouselProps,
  dataStories,
  infographics,
  hideBack,
  area,
  store,
  filterParams,
  varToTopics,
  blocks
}) => {
  const mainTopicDcids =
    fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
  const [showIndex, setShowIndex] = useState<boolean>(false);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [carouselView, setCarouselView] = useState<boolean>(false);
  const [infographic, setInfographic] = useState(null)
  const location = useLocation();
  const blockID = location.hash?.replace("#", "");
  const isBlock = location.hash && blockID > -1 && blockID !== "catalogue";
  const sectionRefs = useRef({});

  const items = blocks[ROOT_TOPIC+'_'+indicator].reduce((a,i)=>{
      let b = i.columns[0].tiles//.map(b=>b.tiles).flat()
      return a.concat(b)
  },[])//.filter(a=>statVar.includes(a.statVarKey[0]))
    


  const color = theme.sdgColors[Number(goal) - 1];
  const statVarSpec = fulfillResponse?.config.categories && fulfillResponse?.config.categories[0];
  
  
  useEffect(() => {
    if (!location.hash && carouselView) setCarouselView(false);
    if (blockID == "catalogue") {
      setCarouselView(true);
      scrollToSection('count')
    }
    scrollToSection(blockID)
  }, [location.hash]);

  const history = useHistory()
  const {backUrl} = useContext(OpenKeysContext)

  const goBack = () => {
    if(!backUrl) goList()
    else history.push(backUrl)
  };
  const goList = () => {
    history.push(routeDataCommonsConstants.GOAL);
  }
  const topics = useStoreState((s) => s.topics);

  const targetText = topics.byDcid[`${ROOT_TOPIC}_${indicator}`]?.name;
  const goalName = topics.byDcid[`${ROOT_TOPIC}_${goal}`]?.name?.replace(goal+':','');
  
  const filterSiblingsAndNext = (current, topics) => {
    const parentPath = current.split('.').slice(0, -1).join('.'); // Get parent path
    const siblings = topics.filter(item => item.startsWith(parentPath) && item.split('.').length === current.split('.').length);
    const currentIndex = siblings.indexOf(current);
    const next = siblings[currentIndex + 1] || null;
    const prev = siblings[currentIndex - 1] || null;
  
    return {
      siblings,
      next,
      prev,
    };
  };
  
  const isWorld = placeDcids[0] == EARTH_PLACE_DCID
  const hasInfo = infographics?.find(a=>a.key==indicator)
  
  const paginator = {
  ...filterSiblingsAndNext(`${ROOT_TOPIC}_${indicator}`, Object.keys(topics.byDcid)),
  count: [
        Number(items?.length), 
        dataStories && Number(Object.keys(dataStories).length),
        Number(hasInfo)
      ].reduce((acc, curr) => acc + (curr || 0), 0)
  }
  
  const scrollToSection = (id) => {
    sectionRefs.current[id]?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <IndicatorLayout id="top">
      <Layout.Content style={{ background: theme.searchBackgroundColor }}>
            <ExploreIndicatorHeader
              key={'catalogue'}
              ref={(el) => {
                if (el) {
                    sectionRefs.current['catalogue'] = el; // Safe assignment
                  }
                }}>
              <Row> 
                  <Col flex="0 1">
                  {!hideBack ?
                    <BackButton size="small" onClick={goBack}>
                      <ArrowLeftOutlined />
                    </BackButton>: <div style={{width:'46px'}}></div>}
                  </Col>
                <Col flex="1 1">
                  <span>
                    <TargetBadge
                      className={`-dc-target-header -dc-target-header-${target}`}
                      color={color}
                    >
                      {indicator}
                    </TargetBadge>
                    {targetText.replace(indicator, "")}
                  </span>
                </Col>
              </Row>
            </ExploreIndicatorHeader>
            <ExploreIndicatorLayout
              carouselView={carouselView}
              paginator={paginator}
            >
              {isWorld && hasInfo && infographics?.filter(a=>a.key==indicator).map((item,i) => 
              <InfographicView 
                key={indicator+'_'+i} 
                item={item} 
                color={color}
                ref={(el) => {
                  if (el) {
                      sectionRefs.current[indicator+'_'+i] = el; // Safe assignment
                    }
                  }}
              />)
              }
              {isWorld && 
              dataStories && Object.values(dataStories).length 
              && Object.values(dataStories)
              .slice(0, carouselView ? Object.values(dataStories).length : 1)
              .map((story, i) => {
                let breadcrumbs = story?.tags?.sort((a, b) => {
                  const aIncludes = a.dcid.includes(`_${goal}`);
                  const bIncludes = b.dcid.includes(`_${goal}`);
                  
                  if (aIncludes && !bIncludes) return -1;
                  if (!aIncludes && bIncludes) return 1;
                  return 0;
              }).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
                let keys = Object.keys(dataStories)
                // let breadcrumbs = story?.tags?.filter(a=>a.dcid.includes(`_${goalNumber}`)).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
                return <StoryView 
                  $color={color} 
                  header={story.name} 
                  key={keys[i]}
                  ref={(el) => {
                    if (el) {
                        sectionRefs.current[keys[i]] = el; // Safe assignment
                      }
                    }}
                >
                  <StoryHeader color={color}>
                      {breadcrumbs?.length && <StoryBreadcrumbs breadcrumbs={breadcrumbs} goalName={goalName}/>}
                      <h4>{story.name}</h4>
                      <Divider dataColor={color}/>
                  </StoryHeader>
                  <StoryContent content={story}/>
              </StoryView>})
              }
              
              {
              // fulfillResponse &&
                items &&
                // statVarSpec &&
                Object.values(items)
                  .slice(0, carouselView ? Object.values(items).length : itemsToShow)
                  .map((item, i) => {
                    return (
                      <GoalChartContentBody key={i}>
                        {/* {isBlock && <ContentCard>{blockTitle}</ContentCard>} */}
                        <GoalContentCard ref={(el) => {
                          if (el) {
                              sectionRefs.current[i] = el; // Safe assignment
                            }
                          }}
                        >
                          {/* <ChartTile
                            fulfillResponse={fulfillResponse}
                            key={`${indicator}-${i}`}
                            placeDcids={placeDcids}
                            tileWithFootnote={{tile:item, footnote: null}}
                            statVarSpec={statVarSpec.statVarSpec}
                          /> */}
                          
                          <Indicator
                            key={i}
                            setter={false}
                            hideAlternates={true}
                            placeDcid={filterParams.location[0] || 'Earth'}
                            store={store}
                            name={item.title}
                            code={item.statVarKey[0]}
                            limit={1}
                          />
                        </GoalContentCard>
                      </GoalChartContentBody>
                    );
                  })}
            </ExploreIndicatorLayout>
            {!carouselView && items && itemsToShow < items.length && (
              // Show "Load More" if there are more items to load
              <ExploreBtn>
                <button onClick={() => setItemsToShow(itemsToShow + 10)}>
                  Explore more
                </button>
              </ExploreBtn>
            )}
      </Layout.Content>
    </IndicatorLayout>
  );
};
export default ExploreIndicator;

const ExploreIndicatorLayout = ({ children, carouselView, name, paginator }) => {
  const location = useLocation();
  const history = useHistory();

  const onNext = () => {
    history.push("?v=" + paginator.next);
  }

  const onPrev = () => {
    history.push("?v=" + paginator.prev);
  }

  if (carouselView) {
    return (
      <ChartCarousel exploreLink={location.search} name={name}>
        {children}
      </ChartCarousel>
    );
  }

  // const childCount = children ? React.Children.count(children) : 0;

  return (
    <ChartCarouselContainer>
      <div className="index-text">
        {paginator.count} Item(s)
        <Link to={location.search + `#catalogue`}>
          <svg
            style={{ marginLeft: "8px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_34887_85347)">
              <path
                d="M1.66699 5.00065H5.00033V14.1673H1.66699V5.00065ZM5.83366 15.834H14.167V3.33398H5.83366V15.834ZM7.50033 5.00065H12.5003V14.1673H7.50033V5.00065ZM15.0003 5.00065H18.3337V14.1673H15.0003V5.00065Z"
                fill="#323232"
              />
            </g>
            <defs>
              <clipPath id="clip0_34887_85347">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
      <div className="ant-carousel">
        <div className="slick-slider slick-initialized" dir="ltr">
          <Button className="slick-arrow slick-prev" disabled={!paginator.prev} onClick={onPrev}>
            <CustomNextArrow />
          </Button>
          <Button className="slick-arrow slick-next" disabled={!paginator.next} onClick={onNext} >
            <CustomPrevArrow />
          </Button>
        </div>
      </div>
      {children}
    </ChartCarouselContainer>
  );
};

const IndicatorLayout = styled(Layout)`
  height: 100%;
  flex-grow: 1;
  > .ant-layout-content {
    > .head {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding: 0.75rem 0 1rem;
    }
  }
`;

const GoalContentCard = styled.div`
  padding: 1.5rem;
  .-dc-indicator-chart {
    > div {
      margin:0 1rem 2rem; 
    }
  }
`

export const ExploreIndicatorHeader = styled(IndicatorHeader)`
  margin: 1px 0 8px;
`;

export const TargetBadge = styled.span`
  border: solid ${({ color }) => color} 1px;
  color: ${({ color }) => color};
  padding: 0px 4px !important;
  margin: 6px 4px 6px 2px;
  display: inline-block !important;
  border-radius: 4px;
  line-height: 18px;
`;
