import {
  CopyOutlined,
  DownloadOutlined,
  LinkOutlined,
  MoreOutlined
} from "@ant-design/icons";
import { Badge, Button, Card, Dropdown, MenuProps, Modal, Space, Tooltip } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { SubAreacard } from "../areas/ThematicAreaView";
import { InfographicModel } from "./GoalWrapper";

const InfographicView: React.FC<{item: InfographicModel; color: string}> = ({item, color}) => {
  const [isDownload, setIsdownload] = useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const items: MenuProps["items"] = [
    {
      label: "Download",
      key: "0",
      icon: <DownloadOutlined />,
      onClick: (e)=>onViewPopup(e),
    },
    {
      label: "Copy sharable link",
      key: "1",
      icon: <LinkOutlined />,
      onClick: (e)=>onViewPopup(e),
    },
  ];
  // const item = infographics.find(a=>a.key==id)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async (item) => {

    let imageUrl = item.URL
    const a = document.createElement('a');
    a.href = imageUrl;
    a.target = '_blank'
    a.download = item["Image name"];
    a.click();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onViewPopup = (e) => {
    if(e.key == 0){
      setIsdownload(true)
      showModal()
    } 
    if(e.key == 1){
      setIsdownload(false)
      showModal()
    } 
  }
  // if(!item) return null

  return (
    <InfoBody>
      <SubAreacard
        style={{
          backgroundColor: "transparent",
          margin: 0,
          padding: "0 0 50px",
        }}
      >{item && 
        <VerticalFlex color={color} vertical={false} className="medium">
          <div className="cover">
            <img src={item.URL} />
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space size="large">
                  <MoreOutlined size="large" />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="label">
            <InfoBadge color={color} count={item.key} /> {item.title}
          </div>
        </VerticalFlex>}
      </SubAreacard>
      <InfographicModal 
        title={`${isDownload ? `Download` : `Share`} Infographic`} 
        open={isModalOpen} 
        // onOk={()=>handleOk(item)} 
        onCancel={handleCancel}
        width={`60%`}
        footer={isDownload?[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" className="success" loading={false} onClick={()=>handleOk(item)}>
            Download
          </Button>,
        ]:[]}
      >
        <div className="cover">
          <FloatinButton onClick={()=>setIsdownload(!isDownload)}>
          {!isDownload ? <span><DownloadOutlined /> Download image</span> :
            <span><LinkOutlined rotate={45}/> Share link</span>}
          </FloatinButton>
          <img src={item.URL} />
        </div>

        <ParamsBody>
        {isDownload 
        ? <>
          <p><strong>Download file</strong></p>
          <p>{item['Image name']}</p>
          <p>Usage: Download images for your presentations. <a>Learn more</a></p>
        </> 
        : <>
          <p><strong>Sharable link</strong></p>
          <p className="sharable">{item.URL}<CopySharableLink link={item.URL}/></p>
          <p>Usage: Share link for websites and social media. <a>Learn more</a></p>
        </>
        }
        </ParamsBody>
      </InfographicModal>
    </InfoBody>
  );
};

export default InfographicView;


interface CopyLinkProps {
  link: string;
}

const CopySharableLink: React.FC<CopyLinkProps> = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <Tooltip title={copied ? "Copied!" : "Copy Link"}>
      <Button icon={<CopyOutlined />} onClick={handleCopy}></Button>
    </Tooltip>
  );
};

const FloatinButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  color: #000;
  :not([href]){
    color: #000!important;
  }
  opacity: .4;
  &.ant-btn svg {
    width: 24px!important;
  }
    &:hover{
      color: #000;
      opacity: 1
    }
`
const InfographicModal = styled(Modal)`
&.ant-modal
  .ant-modal-content .ant-modal-header {
    background-color: transparent!important;
    > .ant-modal-title {
      color: #000!important;
    }
  }
  button.ant-modal-close .ant-modal-close-x .ant-modal-close-icon svg {
      fill: #000!important;
  }
  .ant-modal-body {
    .cover {
        width:60%;
        margin: 0 auto;
        position: relative;
      img {
        width:100%;
        margin-bottom: .75rem;
      }
    }
  }
  .ant-modal-footer {
    .ant-btn {
      border-radius: 4px;
      border: none;
      background: #F2F2F2!important;
      &.success {
        color: #fff;
        background: #467BD5!important;
      }
    }
  }
    @media(max-width: 720px){
      width: 100%!important;
      .ant-modal-body {
        .cover {
            width:100%;
          }
      }
    }
`
const ParamsBody = styled.div`
  margin: 0 -1.5rem;
  padding: 1rem 1.5rem;
  border-top: solid #f2f2f2 1px;
  p {
    padding: 0 0 10px 0;
    margin: 0;
  }
  .sharable {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    padding-right:30px;
    background: #F2F2F2;
    padding: 5px 10px;
    margin: 0 0 5px 0;
    button {
      position: absolute;
      z-index: 2;
      top:0;
      right: 0;
      border: none;
      svg {
        width:24px!important;
      }
    }
  }
`
const InfoBody = styled.div`
  > * {
    margin: 0;
  }
`;
const InfoBadge = styled(Badge)`
  margin-right: 6px;
  .ant-scroll-number {
    border-radius: 4px;
  }
`;
const Flex = styled(Card)`
    border: none!important;
    .ant-card-body {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        background: #FEF2F3;
        padding:0;
        border: solid #fff 16px;
    }
    .cover {
        border-right: solid #fff 16px;
        position: relative;
        width: 60%;
        transition: all .3s;
        img {
            width: 100%;
        }
    }
    .label {
        padding: 24px;
        height: 100%;
        text-align: center;
        font-size: 17px;
        transition: all .3s;
        width: 40%;
    }
      &.medium {
        .cover {
          width: 50%;
        }
        .label {
            font-size: 17px;
            width: 50%;
        }
      }
      &.small {
        .cover {
          width: 40%;
        }
        .label {
            font-size: 17px;
            width: 60%;
        }
      }

      @media(max-width: 720px){
        .label {
          width: 100%!important;
        }
        .cover{
          min-width: calc(100% + 16px);
        }
      }
    .text {
        background: #fff;
        width: 50%;
        height: inherit;
        .ant-col {
            margin-bottom: 16px;
        }
    }
    .ant-dropdown-trigger {
        position: absolute;
        right:0;
        top: 16px;
        svg {
        height: 1.3rem;
        width: 1.3rem;
    }
`;

const VerticalFlex = styled(Flex)`
    .ant-card-body {
        align-items: center;
        background: ${({color})=>color}10;
    }

`