import { CaretDownOutlined } from "@ant-design/icons";
import { Col, Layout, Row, TreeSelect } from "antd";
import Search from "antd/lib/input/Search.js";
import Sider from "antd/lib/layout/Sider";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import useFetchArticles, { GroupedArticles } from "../../../../hooks/useArticles";
import infographics from "../../config/infographics.json";
import { useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { base64Decode, base64Encode, FilterBtn, FilterParams, FilterWrapper, SearchWrapper } from "../areas/ThematicAreaView";
import { getGoalTargetIndicator, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import { OpenKeysContext } from "./Goals";
import GoalWrapper from "./GoalWrapper";

const { SHOW_PARENT } = TreeSelect;
export const unSort = (a:any, b:any) => {
  const [numA, suffixA] = a.split('.');
  const [numB, suffixB] = b.split('.');
  const numComparison = parseInt(numA) - parseInt(numB);
  if (numComparison !== 0) {
      return numComparison;
  }
  // Handle the case where suffix is numeric
  if (!isNaN(suffixA) && !isNaN(suffixB)) {
      return parseInt(suffixA) - parseInt(suffixB);
  }
  // Handle the case where suffix is alphabetic
  if (isNaN(suffixA) && isNaN(suffixB)) {
      return suffixA.localeCompare(suffixB);
  }
  // Handle mixed case: numeric suffixes should come before alphabetic suffixes
  return isNaN(suffixA) ? 1 : -1;
}


const GoalContent: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    selectedVariableDcids: string[];
    variableDcids: string[];
    isSearch: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined
  }> = ({fulfillResponse, placeDcids, variableDcids}) => {
    const location = useLocation()
    const history = useHistory();
    const store = useStoreState((s) => s);
    const { fetchArticles } = useFetchArticles();

    const { openKeys, setOpenKeys, } = useContext(OpenKeysContext);
    const params = Object.fromEntries(new URLSearchParams(location.search))
    const [localIsFetchingFulfillment, setLocalIsFetchingFulfillment] = useState(false);
    const countries = useStoreState((s) => s.countries.dcids.map((dcid) => s.countries.byDcid[dcid]));
    const regions = useStoreState((s) => s.regions.dcids.map((dcid) => s.regions.byDcid[dcid]));
    const [filterPosition, setFilterPosition] = useState<string>("top");
    const [filterParams, setFilterParams] = useState<FilterParams>({
      que: "",
      location: ["Earth"],
      partners: ["all"],
      topics: ["dc/topic/sdg"],
      statVars: [],
    });
    const [topics, setTopics] = useState([]);
    const [goal, target, indicator] = params.v ? getGoalTargetIndicator(params.v) : []
    const [dataStories, setDataStories] = useState<
    false | GroupedArticles | undefined | null
  >(null);
  
    useEffect(()=>{
      let v
      let tmpParams = {...filterParams}
      try {
        v = base64Decode(params.p);
        tmpParams.location = v.split("&")[0] ? v.split("&")[0].split(",") : ["Earth"]
        tmpParams.topics = v.split("&")[1] ? v.split("&")[1].split(",").map(a=>ROOT_TOPIC==a ? a : ROOT_TOPIC+'_'+a) : [ROOT_TOPIC]
        tmpParams.que = v.split("&")[2] ? v.split("&")[2] : ''
      } catch {
        let slug = params.v;
        if(params.v)
        tmpParams.topics = slug.split(",");
      }
      setFilterParams({...tmpParams})
    },[window.location.search]);

    
    useEffect(() => {
      fetch(`${process.env.PUBLIC_URL}/config/sidebar.json`)
        .then((response) => response.json())
        .then((data) => {
          let list = formatTopic(data)
          setTopics(list);
        })
        .catch((error) => console.error("Error loading sidebar.json:", error));
    }, []);


    useEffect(() => {

      if(!dataStories && filterParams?.location?.length == 1 && filterParams?.location.includes(EARTH_PLACE_DCID)){
        
        let alldcids = store?.allTopicDcids?.filter(a=>a.split('.').length>2)
        setLocalIsFetchingFulfillment(true)
        fetchArticles(alldcids).then((resp) => {
          setDataStories(resp);
          setLocalIsFetchingFulfillment(false)
        });
      }


    }, [filterParams.location]);


  useEffect(()=>{
    if(filterParams.que && store) setOpenKeys(store.allTopicDcids)
    else if(!filterParams.que && openKeys) setOpenKeys([])
  },[filterParams.que, store])

  const formatTopic = (data) => {
    return data.filter(a=>!a.key.includes('summary-')).map(a=>{
      let children = a.children ? formatTopic(a.children) : []
      return {...a, value: a.key, children, parents:[]}
    })
  }


    const options = [countries, regions].flat().map((place) => ({ value: place.dcid, title: place.name, id:place.dcid }));
    options.sort(({ value: value1 }, { value: value2 }) => value1.localeCompare(value2));
    
    const treeData = [
      {
        title: "World",
        value: "Earth",
        key: "0",
      },
      ...options
    ];
    
    const onTopicChange = (e) => { 
        const topics = !e.topics.length ? {topics: [ROOT_TOPIC]} : e
      nestedSearch({ ...filterParams, ...topics});
    }


    const onLocationChange = (e) => {
      nestedSearch({ ...filterParams, ...e });
    };

    const queSearch = (e) => {
      nestedSearch({ ...filterParams, que: e.trim() });
    }

    const nestedSearch = (params: FilterParams) => {
      setFilterParams(params);
      let str = serializeGoalParams(params)
      let str64 = base64Encode(str);
      history.push(routeDataCommonsConstants.GOAL + "?p=" + str64);
    };


    const FilterOptions = ({ position }) => {

      const handleFilter = (inputValue, treeNode) => {
        let title = treeNode.title || treeNode.label
        const matchByIndex = treeNode.key?.toString().includes(inputValue);
        const matchByTitle = title?.toLowerCase().includes(inputValue.toLowerCase());
        return matchByIndex || matchByTitle;
      };
      return (
        <FilterWrapper>
          {position == "left" && (
            <div className="sidebar-title">
              Filter content
              <FilterBtn setFilterPosition={setFilterPosition}/>
            </div>
          )}
          <Row className={"position-" + position} gutter={[12, 12]}>
            {position == "top" && (
              <Col className="small" span={1}>
                <FilterBtn setFilterPosition={setFilterPosition}/>
              </Col>
            )}
            <Col className="medium" span={6}>
              <MultipleSelect
                value={filterParams.location}
                placeholder={`Location | World`}
                filterTreeNode={handleFilter}
                onChange={(location) => onLocationChange({location})}
                treeData={treeData}
                exeptionValue={EARTH_PLACE_DCID}
                isSingle={true}
              />
            </Col>
            <Col className="large" span={15}>
              <MultipleSelect
                value={filterParams.topics}
                placeholder={`Goal | All`}
                filterTreeNode={handleFilter}
                onChange={(topics) => onTopicChange({topics})}
                treeData={topics}
                exeptionValue={ROOT_TOPIC}
              />
            </Col>
          </Row>
        </FilterWrapper>
      );
    };
    
    const isAllTopics = filterParams.topics.find(a=>a==ROOT_TOPIC)
    return <Layout className="ant-layout-has-sider" id="top">
              {filterPosition == "left" && <Sider theme="light" width={340}>
                <FilterOptions position={filterPosition} />
              </Sider>}
                <Layout.Content style={{ background: theme.searchBackgroundColor }}>
                    
                <div className="container">
                      <GoalSearchWrapper>
                        <Row gutter={[12, 12]}>
                          <Col span={filterPosition === "none" ? 23 : 24}>
                            
                            <Search 
                              allowClear 
                              key={filterParams.que} 
                              defaultValue={filterParams.que} 
                              onSearch={queSearch} 
                              placeholder="Search" 
                            />
                          </Col>
                          {filterPosition === "none" && (
                            <Col span={1}>
                              <FilterBtn setFilterPosition={setFilterPosition}/>
                            </Col>
                          )}
                        </Row>
                        {filterPosition == "top" && <FilterOptions position={filterPosition} />}
                      </GoalSearchWrapper>
                      {<>
                      {
                        
                      <GoalTablesWrapper>{
                      Object.keys(topics).length && 
                      // localFulfillResponse && 
                      !localIsFetchingFulfillment ? 
                      Object.values(topics)
                          .filter(a=>{
                            let all = filterParams.topics.find(a=>a==ROOT_TOPIC)
                            return all ? a.key != ROOT_TOPIC : filterParams.topics?.map(a=>a.split('.')?.[0])?.includes(a.key)
                          })
                          .map((i:string, topicIndex:number) => {
                            let defOpened = !topicIndex//!!(goal&&goal!='none') ? 2 : 1// || filterParams.thematics?.[0] != "0";
                            return (
                                <GoalWrapper 
                                  goal={goal}
                                  target={target}
                                  indicator={indicator}
                                  placeDcids={[EARTH_PLACE_DCID]} 
                                  filterParams={filterParams} 
                                  key={`${i.key}`} 
                                  area={i} 
                                  store={store} 
                                  opened={defOpened}
                                  dataStories={dataStories as GroupedArticles}
                                  infographics={infographics}
                                />
                              )
                          }
                        ):
                        <ContentCard><Spinner/></ContentCard>
                      }
                      </GoalTablesWrapper>}
                      </>}
                    </div>
                </Layout.Content>
            </Layout>
        
  }
export default GoalContent

const GoalSearchWrapper = styled(SearchWrapper)`
  margin: 1rem 0;
`
export const serializeGoalParams = ({location, topics, que}:FilterParams) => {
  let str = [
      location.join(","), 
      topics?.map(t=>(t as string).replace(ROOT_TOPIC+'_', '')).join(","),
      que
    ].join("&");
  return str;
}

export const MultipleSelect = ({isSingle, value, placeholder, filterTreeNode, onChange, treeData, exeptionValue}) => {
  const [tmpValue, setTmpValue] = useState(value)
  const [open, setOpen] = useState(false)

  const handleChange = (e)=> {
    const added = e?.find((item) => !tmpValue.includes(item));
    const values = tmpValue?.length && e.some((i: string) => i == exeptionValue) ? (added == exeptionValue ? [exeptionValue] : e.filter((i) => i != exeptionValue)) : e;
    
    let result = isSingle&&added ? [added] : values
    setTmpValue(result)
    if(tmpValue?.length > result?.length && !open)
      onChange(result)
  }

  const handleFocus = () => {
    setOpen(true);
  };

  const handleBlur = () => {
    onChange(tmpValue)
    setOpen(false)
  }
  
  return <TreeSelect
    suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
    showSearch
    style={{ width: "100%", display: "block", borderRadius: "50%" }}
    value={tmpValue}
    treeCheckable
    showArrow
    showCheckedStrategy={SHOW_PARENT}
    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
    placeholder={placeholder}
    allowClear={!isSingle}
    filterTreeNode={filterTreeNode}
    treeData={treeData}
    onChange={handleChange}
    open={open}
    onFocus={handleFocus} // Show dropdown on focus
    onBlur={handleBlur} // Hide dropdown on blur
    onRemove={handleBlur} // Hide dropdown on blur
  />
}

const GoalTablesWrapper = styled.div`
  margin-bottom: 56px;
  &:empty {
    &:before {
      content: 'No Data Found';
      display: block;
      color: #000;
      text-align: center;
      padding: 2rem 0;
    }
    background: #fff;
}
    .not-found {
      display: none;
      text-align:center;
    }
      .not-found:first-child{
        display:block;
      }
`